import React from 'react';
import Layout from '../layouts/Layout';
import JobAlerts from '../components/JobAlerts/JobAlerts';
import arrow from '../images/arrow.svg';
import inc from '../images/inc-5000.svg';

import './index.css';

const JobDescription = ({ pageContext }: any) => {
	return (
		<Layout
			title={`${pageContext.title} | JAG Careers`}
			desc={`${pageContext.title} | JAG Careers`}
		>
			<section className={'px-5 py-6 !font-poppins'}>
				<div className={'container mx-auto flex flex-col gap-6'}>
					<a
						href={'/careers/'}
						className={
							'flex max-w-[100px] flex-row font-poppins text-primary hover:text-primary hover:underline'
						}
					>
						<img
							src={arrow}
							width={16}
							className={'mr-3'}
							alt={'Back Arrow.'}
						/>
						Back
					</a>
					<h1
						className={
							'font-poppins text-[40px] font-bold text-primary'
						}
					>
						{pageContext.title}
					</h1>
					<p className="text-base text-body">
						{pageContext.city && pageContext.city !== 'n/a' && (
							<span>
								{pageContext.city + ', ' + pageContext.state}
							</span>
						)}
						{pageContext.fullTime &&
							pageContext.fullTime !== 'n/a' && (
								<span>{' - ' + pageContext.fullTime}</span>
							)}
					</p>
				</div>
			</section>
			<section
				className={
					'bg-white px-3 pb-14 md:pt-6'
				}
			>
				<div className={'container mx-auto'}>
					<div
						className={
							'mt-6 flex flex-row justify-between gap-12 max-lg:flex-col'
						}
					>
						<div
							className={
								'job-description-content flex w-3/4 flex-col max-lg:w-full'
							}
						>
							<h2
								className={
									'mb-6 !font-poppins text-[26px] font-bold text-body'
								}
							>
								Job Description
							</h2>
							<p
								dangerouslySetInnerHTML={{
									__html: pageContext.description
								}}
							/>

							<a
								href={pageContext.url}
								className={'main-button my-6 w-fit ApplyButton ApplyButtonBottom'}
								target={'_blank'}
							>
								Apply Now
							</a>
						</div>
						<div
							className={
								'flex w-1/4 flex-col px-3 max-lg:w-full max-md:px-0 lg:pb-6'
							}
						>
							<img src={inc} className="max-lg:max-w-[100px]" />
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default JobDescription;
